<template>
  <div class="frame">
    <div class="container">
      <h1 class="title">드론 로그 상세보기</h1>
      <div class="info-box">
        <div class="info-center">
          <div class="left">
            <div class="input-box">
              <p>사용자 아이디<strong>*</strong></p>
              <input type="text" placeholder="드론 ID를 입력해주세요" v-model="userInfo.id" maxlength="15" disabled>
            </div>
            <div class="input-box">
              <p>사용자 명<strong>*</strong></p>
              <input type="text" placeholder="이름을 입력해주세요" v-model="userInfo.name" maxlength="7" disabled>
            </div>
            <div class="input-box">
              <p>위도 </p>
              <input type="text" v-model="droneLogInfo.lat" maxlength="18" disabled>
            </div>
            <div class="input-box">
              <p>고도 </p>
              <input type="text" v-model="droneLogInfo.velocity" maxlength="18" disabled>
            </div>
          </div>
          <div class="right">
            <div class="input-box">
              <p>드론 아이디<strong>*</strong></p>
              <input type="text" placeholder="드론 ID를 입력해주세요" v-model="droneLogInfo.deviceId" maxlength="15" disabled>
            </div>
            <div class="input-box">
              <p>기록일시 </p>
              <input type="text" v-model="droneLogInfo.date" maxlength="16" disabled>
            </div>
            <div class="input-box">
              <p>경도 </p>
              <input type="text" v-model="droneLogInfo.lng" maxlength="18" disabled>
            </div>
            <div class="input-box">
              <p>방향 </p>
              <input type="text" v-model="droneLogInfo.direction" maxlength="18" disabled>
            </div>
          </div>
        </div>
        <div class="line"/>
        <div class="info-footer">
            <button class="custom-btn-box btn-back" @click="backButton">목록</button>
            <button class="custom-btn-box btn-delete" @click="showAlert">삭제</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/axios";

export default {
  name: "DroneInfo",
  components: {},
  data() {
    return {
      token: this.$store.state.token.access_token,
      id: this.$route.params.id,
      droneLogInfo: {},
      droneInfo: {
        date: '',
        deviceId: '',
        direction: '',
        drone: {},
        lat: 0,
        lng: 0,
        uuid: '',
        velocity: 0
      },
      userInfo: {
        id: '',
        name: '',
      }
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      if (this.getDroneLogInfo())
        this.getUserInfo()
    },
    getDroneLogInfo() {
      const self = this;
      if (localStorage.droneLogInfo !== undefined) {
        if (JSON.parse(localStorage.droneLogInfo).uuid === self.id) {
          self.droneLogInfo = JSON.parse(localStorage.droneLogInfo);
          return true
        } else {
          self.$swal
              .fire({title: '조회 실패', text: '찾을 수 없는 데이터 입니다..', icon: 'error'})
              .then(() => {
                self.$router.replace({name: 'droneList'})
                return false
              })
        }
      }
    },
    getUserInfo() {
      const self = this;

      const config = {
        method: 'get',
        url: `/users/${self.droneLogInfo.drone.userId}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${self.token}`
        },
      };

      axios(config)
          .then(res => {
            self.userInfo = {...res.data}
          })

    },

    showAlert() {
      const self = this;
      self.$swal.fire({
        title: '삭제하시겠습니까?',
        text: "삭제 시, 복구가 불가능합니다",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '삭제',
        cancelButtonText: '취소'
      }).then((result) => {
        if (result.isConfirmed) {
          self.deleteButton()
        }
      })
    },

    deleteButton() {
      const self = this;

      const config = {
        method: 'delete',
        url: `/locations/${self.id}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${self.token}`
        },
      };
      
      axios(config)
          .then(() => {
            self.$swal
                .fire({title: '삭제 완료', text: '회원이 삭제 되었습니다.', icon: 'success'})
                .then(() => {
                  self.$router.replace({name: 'droneList'})
                })
          })
    },
    backButton() {
        this.$router.replace({name: 'droneList'})
    }
  }
}
</script>

<style scoped>

.frame {
  padding: 40px;
}

.container {
  margin: 0 auto;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.title {
  font-family: 'NotoSansKR', serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 140%;
  margin-bottom: 20px;
}

.info-box {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background: #FFFFFF;
  font-family: 'NotoSansKR', serif;
  font-size: 14px;
}

/* header */

.line {
  background: #E6E7E9;
  height: 1px;
}

/* center */
.info-center {
  display: flex;
}

.left, .right {
  padding: 40px;
  width: 527px;
}

.left .input-box p {
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  margin-bottom: 10px;
}

.right .input-box p {
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  margin-bottom: 10px;
}

.left .input-box strong {
  color: #BE123C;
}

.right .input-box strong {
  color: #BE123C;
}

input {
  height: 40px;
  width: 100%;
  margin-bottom: 20px;
  padding: 10px 16px 10px;
  outline: none;
}

/* footer */
.info-footer {
  background: #FFFFFF;
  border-radius: 0 0 8px 8px;
  height: 73px;
  padding: 17px 16px 16px;
  margin-left: auto;
}

.info-footer .btn-delete {
  background-color: #ff4b4b;
  color: white;
  margin: 0 15px 0 0;
  padding: 11px 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  border: 0;
  width: 67px;
}

.info-footer .btn-back {
    background-color: #ff9800; /* 버튼의 배경색 */
    color: white;
    margin: 0 15px 0 0;
    padding: 11px 12px;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    border: 0;
    width: 67px;
}


</style>